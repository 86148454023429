import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Experimental_CssVarsProvider"] */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@mui/material/styles/CssVarsProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/munchkin-material/munchkin-material/node_modules/next/font/local/target.css?{\"path\":\"lib/munchkinFont.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":\"../fonts/munchkin.woff2\",\"variable\":\"--munchkin-font\"}],\"variableName\":\"munchkinFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/components/I18nProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/home/runner/work/munchkin-material/munchkin-material/packages/site/lib/theme.ts");
